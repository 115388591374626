import React, { useCallback, useRef } from 'react'
import styled, { useTheme } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import { media } from 'styled-bootstrap-grid'
import Prev from 'images/arrow-circle-left.svg'
import Next from 'images/arrow-circle-right.svg'
import { Section } from '../Layout'
import BlogCard from '../Blog/BlogCard'
import useWindowSize from '../../hooks/useGatsbyWindowSize'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const SliceHeader = styled.div`
  display: grid;
  gap: 20px;
  ${media.md`
    grid-template-columns: 1.5fr 1fr;
  `}
`

const Title = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeights.titleBold};
  color: ${({ theme }) => theme.palette.secondary.main};
`

const StyledSwiperSlide = styled(SwiperSlide)`
  width: auto;
  max-width: 400px;
`

const StyledSwiper = styled(Swiper)`
  margin-top: 40px;
`

const StyledNavigation = styled.div`
  display: flex;
  gap: 20px;
`

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`

const ButlletAndNavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`

const RelatedArticlesSlice = ({ tags, title }) => {
  const sliderRef = useRef(null)
  const { width } = useWindowSize()

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slidePrev()
  }, [])

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slideNext()
  }, [])
  const theme = useTheme()
  const data = useStaticQuery(graphql`
    query RelatedBlogsQuery {
      allContentfulBlogPost(sort: { fields: [firstPublishedAt], order: DESC }) {
        nodes {
          title
          childContentfulBlogPostContentTextNode {
            childMarkdownRemark {
              html
              timeToRead
            }
          }
          topicTags
          firstPublishedAt
          metaDescription {
            metaDescription
          }
          shortDescription
          heroImage {
            gatsbyImageData(quality: 80, width: 400, placeholder: NONE)
            description
          }
          slug
        }
      }
    }
  `)

  const sliceContent = { title: [<Title>Related articles</Title>] }
  const articles = data.allContentfulBlogPost.nodes.filter(article => {
    const matchingElements = article.topicTags.filter(tag => tags.includes(tag))
    return matchingElements.length >= 2 && article.title !== title
  })

  const calculateSlides = () => {
    if ((articles.length === 3  || articles.length === 2 )&& width > 1280) return 3
    if (articles.length === 2 && width > 860) return 2
    if (articles.length === 1) return 1
    return 'auto'
  }

  const slides = calculateSlides()

  return (
    <Section bgStyle="dark">
      <SliceHeader>{sliceContent.title}</SliceHeader>
      <StyledSwiper
        ref={sliderRef}
        spaceBetween={20}
        slidesPerView={slides}
        pagination={{ el: '.swiper-custom-pagination' }}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {articles.map(article => (
          <StyledSwiperSlide>
            <BlogCard post={article} slideItem />
          </StyledSwiperSlide>
        ))}
      </StyledSwiper>
      <ButlletAndNavigationContainer
        style={slides !== 'auto' ? { display: 'none' } : {}}
      >
        <div
          style={{
            '--swiper-pagination-color': theme.palette.secondary.main,
            '--swiper-pagination-bullet-inactive-color':
              theme.palette.background.gray,
          }}
          className="swiper-custom-pagination"
        />
        <StyledNavigation>
          <Button type="button" className="prev-arrow" onClick={handlePrev}>
            <Prev />
          </Button>
          <Button type="button" className="next-arrow" onClick={handleNext}>
            <Next />
          </Button>
        </StyledNavigation>
      </ButlletAndNavigationContainer>
    </Section>
  )
}

export default RelatedArticlesSlice
